<template>
  <div class="page-listings">
    <pricelist-tour v-if="$store.state.tour & !loading" />
    <section
      v-if="loading || !parts"
      data-v-5a90ec03
      class="pt-5 pb-2 bg9 bs-shadow mobile-top"
      style="min-height: 198px"
    >
      <div data-v-5a90ec03 class="container">
        <h1 v-if="this.listType == 'COMMERCIAL'" class="text-white mb0 lh1">
          Loading Commercial Price List....
        </h1>
        <h1 v-else class="text-white mb0 lh1">
          Loading Service Price List....
        </h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </section>
    <section v-else class="pt-5 pb-2 bg9 bs-shadow mobile-top">
      <div data-v-5a90ec03 class="container">
        <input
          id="pricelist-step-1"
          class="top-search bs-large mt0"
          type="text"
          placeholder="Search by Catalog group, Catalog Number, or Description"
          v-model="search"
        />
        <div class="search-btns">
          <div id="pricelist-step-2" class="radios">
            <div class="control-group">
              <label class="control control--radio">
                Service
                <input
                  @click="serviceSyncer"
                  type="radio"
                  name="radio"
                  v-model="listType"
                  value="SERVICE"
                />
                <div class="control__indicator"></div>
              </label>
              <label class="control control--radio">
                Commercial
                <input
                  @click="commercialSyncer"
                  type="radio"
                  name="radio"
                  v-model="listType"
                  value="COMMERCIAL"
                />
                <div class="control__indicator"></div>
              </label>
            </div>
          </div>

          <div class="left-side">
            <!--
            <div class="ac-select">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="acFilter"
              >
                <option :value="null" selected class="all-contractors">
                  Area Contractor
                </option>
                <option v-for="ac in acs" :key="ac.acid" :value="ac.acid">
                  {{ " " + toUpperCase(ac.acName) }}
                </option>
           
              </select>
            </div>
            -->
            <!--
            <div class="dates" id="dates">
              <datepicker
                format="MMM dd yyyy"
                placeholder="From Date"
                v-model="startDate"
                wrapperClass="pickthedate"
              ></datepicker>
              <datepicker
                format="MMM dd yyyy"
                placeholder="To Date"
                v-model="endDate"
                wrapperClass="pickthedate"
              ></datepicker>
            </div>
            -->
          </div>
          <div class="right-side">
            <button
              v-if="search.length && searchStarted == false"
              @click.prevent="searchParts"
              class="search"
            >
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>

            <button v-else class="search" disabled>
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>
            <button v-if="search.length" @click="clearSearch" class="clear">
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
            <button v-else disabled class="clear">
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
          </div>
        </div>

        <!--
        <div class="date-area">
          <vc-calendar mode="range" v-model="attributes" :max-date="new Date()" is-inline />
        </div>
        -->
      </div>
    </section>
    <!--

		<section v-else class="py-5 bg9 bs-shadow mobile-top">
			<div class="container">
				<input
					class="top-search bs-large mt0"
					type="text"
					placeholder="Search parts by order number, invoice number, or job name"
					v-model="search"
					@click="searchReset()"
				/>
			</div>
		</section>
    -->
    <div class="container">
      <div class="row">
        <div
          v-if="loading == true"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
        >
          <div class="flex-box-wrap pt150 pb150">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />
              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="
            loading == false && !filteredparts.length && searchStarted == true
          "
          class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
        >
          <div class="sorry-box">
            <img src="@/assets/img/sad.svg" />
            <h3>Sorry, no parts were found...</h3>
            <button class="btn-primary" @click="firstLoad">
              Show me some parts
            </button>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <div
            class="
              gsa-header
              d-flex
              align-items-center
              p-3
              mt-3
              mb10
              rounded
              box-shadow
            "
          >
            <p class="mrauto mbo">
              Page {{ currPage }} of
              {{ Math.ceil(this.filteredparts.length / 100) }}
            </p>
            <div class="pag-wrapper mlauto">
              <div id="pricelist-step-3" class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    this.currPage === this.totalPage ||
                    this.currPage == Math.ceil(this.filteredparts.length / 100)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>

              <!--
          <p class="mtauto" v-if="search.length">Results are being filtered</p>
          <p class="mtauto" v-else>Page {{currPage}} of {{ totalPage }} </p>
          <div class="nav-btns">

              <button v-if="this.currPage === 1" disabled><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-else @click.prevent="setPage(currPage-1)"><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-if="this.currPage === this.totalPage" disabled>Next <i class="material-icons">keyboard_arrow_right</i></button>

              <button v-else @click.prevent="setPage(currPage+1)">Next <i class="material-icons">keyboard_arrow_right</i></button>

          </div>
              -->
            </div>
          </div>

          <div>
            <div class="listing">
              <div class="d-flex">
                <div class="mr-auto">
                  <div v-if="this.listType == 'COMMERCIAL'">
                    <h2>Commercial Price List</h2>
                    <small>{{ filteredparts.length }} Parts</small>
                  </div>

                  <div v-else>
                    <h2>Service Price List</h2>
                    <small>{{ filteredparts.length }} Parts</small>
                  </div>
                </div>

                <div class="mlauto">
                  <download-csv
                    id="pricelist-step-4"
                    class="mb0 lh1 list-btn dib d-flex center"
                    :data="parts"
                    :name="'name' + '.csv'"
                    >Download CSV</download-csv
                  >
                </div>
              </div>

              <!--
          <div v-for="gsa in parts" :key="gsa.Tid">
              -->
              <div class="gsa table-responsive">
                <table
                  class="table-striped table-bordered"
                  style="display: table; min-width: 100%"
                >
                  <thead>
                    <tr>
                      <th
                        @click="sortCol('price_List_No')"
                        class="table-link ten"
                        :class="
                          columnSort == 'price_List_No' ? 'column-active' : ''
                        "
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Type
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'price_List_No'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('catalog_Group')"
                        class="table-link ten"
                        :class="
                          columnSort == 'catalog_Group' ? 'column-active' : ''
                        "
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Cat Group
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'catalog_Group'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('catalog_No')"
                        class="table-link ten"
                        :class="
                          columnSort == 'catalog_No' ? 'column-active' : ''
                        "
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Cat No
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'catalog_No'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('description')"
                        class="table-link forty"
                        :class="
                          columnSort == 'description' ? 'column-active' : ''
                        "
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Description
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'description'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('list_Price')"
                        class="table-link twenty"
                        :class="
                          columnSort == 'list_Price' ? 'column-active' : ''
                        "
                        scope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          List Price
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'list_Price'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                      <th
                        @click="sortCol('weight_Gross')"
                        class="table-link ten"
                        :class="
                          columnSort == 'weight_Gross' ? 'column-active' : ''
                        "
                        cope="col"
                      >
                        <span style="display: flex; flex: 0 0 100%">
                          Wt. (lbs.)
                          <span class="ml-auto">
                            <i
                              :class="
                                columnSort == 'weight_Gross'
                                  ? 'carrot-icon-active'
                                  : ''
                              "
                              class="material-icons carrot-icon"
                            >
                              filter_list
                            </i>
                          </span>
                        </span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-bind:key="index"
                      v-for="(part, index) in filteredparts.slice(
                        pageStart,
                        pageStart + countOfPage
                      )"
                    >
                      <td>{{ part.price_List_No }}</td>
                      <td>{{ part.catalog_Group }}</td>
                      <td>{{ part.catalog_No }}</td>
                      <td>{{ part.description }}</td>
                      <td>{{ part.list_Price | currency }}</td>
                      <td>{{ part.weight_Gross }} lbs</td>
                      <!--
                      <td>Sheet</td>
                      -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- card -->
            </div>
          </div>

          <div
            class="
              gsa-header
              d-flex
              align-items-center
              p-3
              mb-3
              mt10
              rounded
              box-shadow
            "
          >
            <p class="mrauto mbo">
              Page {{ currPage }} of
              {{ Math.ceil(this.filteredparts.length / 100) }}
            </p>
            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    this.currPage === this.totalPage ||
                    this.currPage == Math.ceil(this.filteredparts.length / 100)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>

              <!--
          <p class="mtauto" v-if="search.length">Results are being filtered</p>
          <p class="mtauto" v-else>Page {{currPage}} of {{ totalPage }} </p>
          <div class="nav-btns">

              <button v-if="this.currPage === 1" disabled><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-else @click.prevent="setPage(currPage-1)"><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-if="this.currPage === this.totalPage" disabled>Next <i class="material-icons">keyboard_arrow_right</i></button>

              <button v-else @click.prevent="setPage(currPage+1)">Next <i class="material-icons">keyboard_arrow_right</i></button>

          </div>
              -->
            </div>
          </div>
        </div>
        <!-- end other column -->
      </div>
    </div>
    <!-- container -->
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";
import Datepicker from "vuejs-datepicker";
import JsonCSV from "vue-json-csv";
import pricelistTour from "../components/Tours/pricelistTour";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "Parts",
  components: {
    AtomSpinner,
    Avatar,
    Datepicker,
    JsonCSV,
    pricelistTour,
  },
  data() {
    return {
      ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
      backend: process.env.VUE_APP_SERVER_URL,
      searchValue: "",
      search: "",
      storedListings: [],
      searchRender: false,
      startDate: null,
      endDate: null,
      searchStarted: false,
      acs: [],
      parts: [],
      selectedCat: "Show All",
      acFilter: null,
      searching: false,
      loading: true,
      countOfPage: 100,
      currPage: 1,
      noGSAFound: false,
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
      PDFload: false,
      PDFfile: null,
      PDFError: false,
      formattedData: [],
      listType: "SERVICE",
      colSort: false,
      columnSort: "",
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.searchValue = "";
      this.startDate = null;
      this.acFilter = null;
      this.endDate = null;
      this.searchStarted = false;
      this.$store.commit("setActivePage", "order-manage");

      return axios
        .get(`${this.ordermanageapi}/pricelist?type=${this.listType}`, auth)
        .then((response) => {
          //console.log(response);
          this.parts = response.data.prices;
          this.loading = false;
          this.$store.commit("UPDATE_COMMERCIAL", this.parts);
          //const unique = [...new Set(this.parts.map(item => item.catalog_Group + item.catalog_Group_Desc))];

          /*const unique = [...new Set(this.parts.map(item => item.catalog_Group ))];
            console.log("cats " + unique);

            let newList = [];
            for (let i = 0; i < unique.length; i++) {

                this.parts.filter(function (e) {
                    if(e.catalog_Group == unique[i]){
                        newList.push(e);
                    }                 
                });

            }
            console.log("new list " + newList.length);
            console.log("original list " + this.parts.length);
            this.formattedData = newList;
            */
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    clearSearch: function () {
      this.searchStarted = false;
      this.search = "";
    },
    searchParts: function () {
      this.searchStarted = true;
    },

    setPage: function (idx) {
      //this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },

    catSwitcher: function (catType) {
      //console.log(catType);
      let category = catType;
      //console.log(category);
      if (category !== "Show All") {
        this.selectedCat = category;
        this.search = "";
        //console.log(category);
      } else {
        this.selectedCat = "Show All";
      }
    },

    toUpperCase: function (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.toUpperCase();
      });
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    commercialSyncer: function () {
      //console.log('Commercial');
      this.listType = "COMMERCIAL";
      this.loading = true;
      return axios
        .get(`${this.ordermanageapi}/pricelist?type=COMMERCIAL`, auth)
        .then((response) => {
          //console.log(response);
          this.parts = response.data.prices;
          this.loading = false;
        });
    },
    serviceSyncer: function () {
      //console.log('Service');
      this.listType = "SERVICE";
      this.loading = true;
      return axios
        .get(`${this.ordermanageapi}/pricelist?type=SERVICE`, auth)
        .then((response) => {
          //console.log(response);
          this.parts = response.data.prices;
          this.loading = false;
        });
    },
    sortCol: function (type) {
      this.columnSort = type;
      const COLUMN = type;
      //console.log(COLUMN)
      if (this.colSort) {
        this.filteredparts.sort((a, b) => (a[COLUMN] > b[COLUMN] ? 1 : -1));
      } else {
        this.filteredparts.sort((a, b) => (a[COLUMN] < b[COLUMN] ? 1 : -1));
      }
      this.colSort = !this.colSort;
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredparts: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "" && this.searchStarted == true) {
        return this.parts.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.description.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            e.catalog_No.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            e.catalog_Group.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.parts;
      }
    },

    foundNoGSA: function () {
      if (this.search.length > 1 && this.filteredparts.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.parts.length / this.countOfPage);
    },

    listingRestore: function () {
      if (
        !this.searchValue &&
        !this.loading &&
        !this.searching &&
        this.storedListings.length >= this.listings
      ) {
        //console.log("fired off");
        this.listings = this.storedListings;
      }
      return;
    },
    searchEndpointCompute: function () {
      //has order number, no start, no end, no ac
      if (
        this.searchValue.length &&
        !this.startDate &&
        !this.endDate &&
        !this.acFilter
      ) {
        return `${this.ordermanageapi}/pricelist?search=${this.searchValue}`;
      } else if (
        //has order number, and start, no end, no ac
        this.searchValue.length &&
        this.startDate != null &&
        this.endDate === null &&
        this.acFilter === null
      ) {
        return `${this.ordermanageapi}/pricelist?search=${
          this.searchValue
        }&start=${this.formatDate(this.startDate)}`;
      } else if (
        //has order number, and end, no start, no ac
        this.searchValue.length &&
        this.startDate === null &&
        this.acFilter === null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/pricelist?search=${
          this.searchValue
        }&end=${this.formatDate(this.endDate)}`;
      } else if (
        // has three, no ac
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter === null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/pricelist?search=${
          this.searchValue
        }&start=${this.formatDate(this.startDate)}&end=${this.formatDate(
          this.endDate
        )}`;
      } else if (
        //only has end
        !this.searchValue &&
        this.startDate === null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/pricelist?end=${this.formatDate(
          this.endDate
        )}`;
      } else if (
        //only has start
        !this.searchValue &&
        this.startDate != null &&
        this.endDate === null
      ) {
        return `${this.ordermanageapi}/pricelist?start=${this.formatDate(
          this.startDate
        )}`;
      } else if (
        //has start and end
        !this.searchValue &&
        this.startDate != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/pricelist?start=${this.formatDate(
          this.startDate
        )}&end=${this.formatDate(this.endDate)}`;
      } else if (
        //has all 4
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/pricelist?ac=${this.acFilter}`;
      } else if (
        //has only ac
        !this.searchValue &&
        this.startDate === null &&
        this.endDate === null &&
        this.acFilter != null
      ) {
        return `${this.ordermanageapi}/pricelist?ac=${this.acFilter}`;
      } else if (
        //has ac and start
        !this.searchValue &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate === null
      ) {
        return `${this.ordermanageapi}/pricelist?start=${this.formatDate(
          this.startDate
        )}&ac=${this.acFilter}`;
      } else if (
        //has ac and end
        !this.searchValue &&
        this.startDate === null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/pricelist?end=${this.formatDate(
          this.endDate
        )}&ac=${this.acFilter}`;
      } else if (
        //has ac and number
        this.searchValue.length &&
        this.startDate === null &&
        this.acFilter != null &&
        this.endDate === null
      ) {
        return `${this.ordermanageapi}/pricelist?search=${this.searchValue}&ac=${this.acFilter}`;
      } else if (
        //has ac and start and end
        !searchValue.length &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/pricelist?ac=${
          this.acFilter
        }&start=${this.formatDate(this.startDate)}&end=${this.formatDate(
          this.endDate
        )}`;
      } else {
        return `${this.ordermanageapi}/pricelist`;
      }
    },
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.listing {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.gsa-bottom {
  align-items: flex-end;
}
.gsa-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: scroll;
}

.table-responsive {
  display: table;
  margin: 0;
  padding: 0;
  min-width: 100%;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
  line-height: 1;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
  padding: 23px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.search-btns .clear {
  margin-right: 0;
  margin-left: auto;
  padding: 23px 15px;
}
.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.pdfButton {
  cursor: pointer;
}
.radios {
  color: #fff;
}

/* radios */
.control-group {
  margin-top: 5px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.control {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
.control input:checked ~ .control__indicator {
  background: #4d5967;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #4d5967;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}
.right-side {
  text-align: right;
  flex: 1;
}
</style>
